export const SP_CODES = [
  {
    code: 'DC',
    desc: 'CONTENEDOR DAÑADO',
  },
  {
    code: 'DM',
    desc: 'CONTENEDOR DEFORMADO',
  },
  {
    code: 'HB',
    desc: 'DIRECTO',
  },
  {
    code: 'AN',
    desc: 'ARTICULO 15',
  },
  {
    code: 'VG',
    desc: 'APARTADO Y VIGILADO',
  },
  {
    code: 'CB',
    desc: 'CARGA CON BOMBEROS',
  },
  {
    code: 'DB',
    desc: 'DESCARGA CON BOMBEROS',
  },
  {
    code: 'UA',
    desc: 'CONTENEDOR INSPECCIÓN',
  },
  {
    code: 'FT',
    desc: 'FLEXITANK',
  },
  {
    code: 'SM',
    desc: 'SMART CONTAINER',
  },
  {
    code: 'UD',
    desc: 'UNDERDECK',
  },
  {
    code: 'OD',
    desc: 'ON DECK',
  },
  {
    code: 'LL',
    desc: 'FALTO DESPACHO',
  },
  {
    code: 'TC',
    desc: 'TEMPERATURA CONTROLADA',
  },
  {
    code: 'NX',
    desc: 'REEFER NO CONEXIÓN',
  },
  {
    code: 'RN',
    desc: 'REMOCIÓN ALTURA',
  },
  {
    code: 'XX',
    desc: 'CONFIRMAR OFICINA',
  },
  {
    code: 'XX',
    desc: 'IMO SIN AUTORIZAR',
  },
  {
    code: 'XX',
    desc: 'RESPETAR POSICIÓN SISTEMA',
  },
  {
    code: 'XX',
    desc: 'POSICIONAR POZA NORTE',
  },
  {
    code: 'XX',
    desc: 'POSICIONAR POZA SUR',
  },
  {
    code: 'DR',
    desc: 'PUERTAS ABIERTAS',
  },
  {
    code: 'XX',
    desc: 'BREAKBULK',
  },
  {
    code: 'XX',
    desc: 'RESPETAR POSICIÓN',
  },
  {
    code: 'XX',
    desc: 'POSICIONAR TINGLADO',
  },
];

export const getSpCodeDescription = (spCode: string | undefined) => (
  SP_CODES.find(specialCode => specialCode.code === spCode) || { desc: '-' }
).desc;
