import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-plug-off-dialog',
  templateUrl: './plug-off-dialog.component.html',
  styleUrls: ['./plug-off-dialog.component.scss']
})
export class PlugOffDialogComponent {

  reefersToDisconnect: Array<{ cntrId: string; ubication: string; }>;

  constructor(
    public dialogRef: MatDialogRef<PlugOffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<{ cntrId: string; ubication: string; }>
  ) {
    this.reefersToDisconnect = [...data];
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

}
