export enum PLANE_GRID {
  DECK = 'DECK',
  HATCH = 'HATCH',
};

export enum GRID_CELL {
  EMPTY = 'EMPTY',
  LOCKED = 'LOCKED',
  IN_USE = 'IN_USE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
};

export enum OverSizeType {
  UP = 'up',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom'
};

export enum MOV_TYPE {
  VSLD = 'VSLD',
  VSDS = 'VSDS',
};

export enum MOV_STATUS {
  A = 'A',
  M = 'M',
  S = 'S',
  E = 'E',
  J = 'J',
  T = 'T',
  O = 'O',
  R = 'R',
  C = 'C',
  L = 'L',
};

export type BaySpCode = {
  [key: string]: {
    load: Array<string | undefined>;
    discharge: Array<string | undefined>;
  }
};

export interface PlannerViewAPIResponse {
  voyageId:          string;
  voyageName:        string;
  arrivalDatetime:   string;
  departureDatetime: string;
  berthingDatetime:  string;
  voyageStatus:      string;
  isActive:          boolean;
  vessel:            Vessel;
}

export interface Vessel {
  vesselId:         number;
  vesselName:       string;
  lastModifiedDate: string;
  vesselModel:      VesselModel;
  bayList:          BayList[];
  maskList:         Mask[];
  segmentList:      any[];
}

export interface BayList {
  bayNo:         string;
  cellDeckList:  CellList;
  cellHatchList: CellList;
}
​
export interface CellList {
  lanes:    string[];
  tiers:    string[];
  cellList: CellListElement[];
}
​
export interface CellListElement {
  bayRowId:  string;
  baytierId: string;
  mask:      Mask | null;
  segment:   Segment;
  slot:      Slot;
}

export interface Segment {
  segmentId: string;
  colorRgb: string;
}

export interface Mask {
  maskId:          number;
  maskDescription: string;
  colorRgb:        string;
  cntrSize:        string;
  cntrLength:      string;
  cntrType:        string;
  cntrStatus:      string;
  dgCode:          string;
  spCode:          string;
  cntrId:          string;
  grossWeight:    number;
}

export interface Slot {
  discharge: Cell;
  load:      Cell;
}

export interface Cell {
  cellStatus: CellStatusEnum;
  cellData:   CellData | null;
}

export interface CellData {
  cntrId:             string;
  cntrSize:           string;
  cntrType:           string;
  cntrIso:            string;
  cntrStatus:         string;
  damageCode:         string;
  isFull:             boolean;
  specialHdlCode:     string;
  ubication:          string;
  grossWeight:        number;
  movement:           Movement;
  sequence:           string;
  cntrSizeFlags:      { [key: string]: boolean };
  cntrMovStatusFlags: CntrMOVStatusFlags;
  isImo:              boolean;
  /** Si es un contenedor refrigerado o no */
  isReefer:           boolean;
  /** Identifica que el contenedor tiene que estar enchufado  */
  isRequiredPlugOn:   boolean;
  isRemo:             boolean;
  isPendingEntry:     boolean;
  isTransit:          boolean;
  isLoading:          boolean;
  overSize:           OverSize;
  pozaLane?:          number;
  pod?:               string;
  colorRgb?:          string;
  /** Flag que indica si se ha solicitado desconexión de contenedor (1) o ya se ha desconectado (2) */
  reqPlugOff?:        1 | 2;
}

export interface CntrMOVStatusFlags {
  isActive:    boolean;
  isMoving:    boolean;
  isEntering:  boolean;
  isCompleted: boolean;
}

export interface Movement {
  movType:   string;
  movStatus: string;
}

export interface OverSize {
  up: boolean;
  left: boolean;
  right: boolean;
  bottom: boolean;
}

export interface VesselModel {
  vesselModelId:    number;
  vesselModelName:  string;
  modifiedDatetime: string;
  hatchCount:       number;
  depth:            number;
  totalCellCount:   number;
  totalRowCount:    number;
  totalTierCount:   number;
  bayCount:         number;
}

export enum CellStatusEnum {
  EMPTY = 'EMPTY',
  LOCKED = 'LOCKED',
  IN_USE = 'IN_USE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}
