import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-poza-lane-dialog',
  templateUrl: './poza-lane-dialog.component.html',
  styleUrls: ['./poza-lane-dialog.component.scss']
})
export class PozaLaneDialogComponent implements OnInit {

  currentPozaLaneSelected!: number | string;

  constructor(
    private dialogRef: MatDialogRef<PozaLaneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: number | string }
  ) {
    this.currentPozaLaneSelected = data.currentPozaLane;
  }

  ngOnInit() { }

  onPozaLaneChange(evt: MatSelectChange) {
    this.currentPozaLaneSelected = evt.value;
  }

  saveChanges() {
    this.dialogRef.close({ newValue: +this.currentPozaLaneSelected });
  }

}
