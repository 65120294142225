import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { SharedModule } from 'src/app/modules';

import { CellContainerComponent } from './cell-container/cell-container.component';
import { HeaderComponent } from './header/header.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { PlugOffDialogComponent } from './plug-off-dialog/plug-off-dialog.component';
import { PozaLaneDialogComponent } from './poza-lane-dialog/poza-lane-dialog.component';

const UI_COMPONENTS = [
  CellContainerComponent,
  HeaderComponent,
  LoadingDialogComponent,
  NavigationBarComponent,
  PlugOffDialogComponent,
  PozaLaneDialogComponent,
];

@NgModule({
  declarations: [...UI_COMPONENTS],
  imports: [
    SharedModule,
  ],
  entryComponents: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
  ],
  exports: [...UI_COMPONENTS]
})
export class UIComponentsModule { }
