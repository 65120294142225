import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { BaySpCode, PLANE_STATUS_SWITCH, PLANE_VIEW_MODE } from 'src/app/models';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnChanges {

  @Input() bays: Array<string>;
  @Input() baySpCodes: BaySpCode;
  @Input() reeferModeFromExternalSource: boolean;
  @Input() vesselName: string;
  @Input() voyageId: string;

  @Output() baySelectedChange: EventEmitter<number>;
  @Output() previousBay: EventEmitter<void>;
  @Output() nextBay: EventEmitter<void>;
  @Output() planeViewModeChange: EventEmitter<PLANE_VIEW_MODE>;
  @Output() slotChange: EventEmitter<string | PLANE_STATUS_SWITCH>;
  @Output() disconnectReeferModeSelected: EventEmitter<boolean>;
  @Output() dispatchPlugOff: EventEmitter<boolean>;
  @Output() topViewModeSelected: EventEmitter<boolean>;

  iterableBays: Array<number>;
  isDisconnectReeferModeSelected: boolean;
  isTopViewSelected: boolean;
  selectedBayIndex: number;
  selectedPlane: string = PLANE_VIEW_MODE.ALL;
  spCodesLegend: any;
  statusSwitch: string = PLANE_STATUS_SWITCH.DOWNLOAD;

  constructor() {
    this.bays = [];
    this.baySelectedChange = new EventEmitter();
    this.baySpCodes = {};
    this.iterableBays = [];
    this.isDisconnectReeferModeSelected = false;
    this.isTopViewSelected = false;
    this.nextBay = new EventEmitter();
    this.previousBay = new EventEmitter();
    this.planeViewModeChange = new EventEmitter();
    this.reeferModeFromExternalSource = false;
    this.selectedBayIndex = 0;
    this.spCodesLegend = { deck: [], hatch: [] };
    this.slotChange = new EventEmitter();
    this.disconnectReeferModeSelected = new EventEmitter(false);
    this.dispatchPlugOff = new EventEmitter(false);
    this.topViewModeSelected = new EventEmitter(false);
    this.vesselName = '';
    this.voyageId = '';
  }

  ngOnInit() {
    this.baySelectedChange.emit(this.selectedBayIndex);
    this.disconnectReeferModeSelected.emit(this.isDisconnectReeferModeSelected);
    this.topViewModeSelected.emit(this.isTopViewSelected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes['bays']) {
      this.selectedBayIndex = 0;
      this.iterableBays = changes['bays'].currentValue;
    }
    if(changes && changes['bays'] && changes['baySpCodes']) {
      this.spCodesLegend = this.baySpCodes[this.iterableBays[this.selectedBayIndex]];
    }
    if(changes && changes['reeferModeFromExternalSource']) {
      this.isDisconnectReeferModeSelected = false;
    }
  }

  onStatusSwitchChange() {
    this.statusSwitch = this.statusSwitch === PLANE_STATUS_SWITCH.LOAD
      ? PLANE_STATUS_SWITCH.DOWNLOAD
      : PLANE_STATUS_SWITCH.LOAD;
    this.slotChange.emit(this.statusSwitch);
    this._resetDisconnectMode();
  }

  goToPreviousBay() {
    if(this.selectedBayIndex > 0) {
      this.selectedBayIndex -= 1;
      this._resetDisconnectMode();
      this._updateSpCodelegend();
      this.previousBay.emit();
    }
  }

  goToNextBay() {
    if(this.selectedBayIndex < this.iterableBays.length) {
      this.selectedBayIndex += 1;
      this._resetDisconnectMode();
      this._updateSpCodelegend();
      this.nextBay.emit();
    }
  }

  onBaySelectionChange(event: MatSelectChange) {
    this._resetDisconnectMode();
    this._updateSpCodelegend();
    this.baySelectedChange.emit(event.value);
  }

  onSwitchPlaneChange(event: any) {
    const plane: PLANE_VIEW_MODE = event.value;
    this.planeViewModeChange.emit(plane);
  }

  onTopVisualizationMode() {
    this.isTopViewSelected = !this.isTopViewSelected;
    this.topViewModeSelected.emit(this.isTopViewSelected);
  }

  toggleReeferMode() {
    this.isDisconnectReeferModeSelected = !this.isDisconnectReeferModeSelected;
    this.disconnectReeferModeSelected.emit(this.isDisconnectReeferModeSelected);
  }

  dispatchPlugOffWave() {
    this.dispatchPlugOff.emit(true);
  }

  private _resetDisconnectMode() {
    this.isDisconnectReeferModeSelected = false;
    this.disconnectReeferModeSelected.emit(this.isDisconnectReeferModeSelected);
  }

  private _updateSpCodelegend() {
    this.spCodesLegend = this.baySpCodes[this.iterableBays[this.selectedBayIndex]];
  }

}
