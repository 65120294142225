export enum PLANE_VIEW_MODE {
  ALL = 'ALL',
  DECK = 'DECK',
  HATCH = 'HATCH',
};

export enum PLANE_STATUS_SWITCH {
  LOAD = 'load',
  DOWNLOAD = 'discharge',
};
