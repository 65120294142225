<div class="flex-col dialog-container">
  <div class="flex-row header">
    <div class="flex-row">
      <span class="dialog-title">Disconnect Reefer</span>
    </div>
    <div class="flex-row">
      <button mat-icon-button (click)="onCancel()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="flex-row heading-text">
    <h2>This is the containter list</h2>
  </div>
  <div class="flex-col container-list">
    <div class="flex-row"></div>
    <mat-chip-listbox aria-label="Fish selection">
      <mat-chip *ngFor="let container of reefersToDisconnect">{{ container.cntrId }}</mat-chip>
    </mat-chip-listbox>
    <div class="flex-row no-reefers" *ngIf="!reefersToDisconnect || reefersToDisconnect.length === 0">
      There is no reefers selected yet
    </div>
  </div>
  <div class="flex-row" mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="onCancel()">Cancel</button>
    <button
      color="primary"
      mat-raised-button
      cdkFocusInitial
      [disabled]="!reefersToDisconnect || reefersToDisconnect.length === 0"
      (click)="onConfirm()">
        Confirm
    </button>
  </div>
</div>
