<div class="flex-col">
  <div class="flex-row close-modal" mat-dialog-close>X</div>
  <div class="flex-row title">POZA LANE</div>
  <div class="flex-row text">Select the new value for poza lane:</div>
  <div class="flex-row select">
    <mat-form-field appearance="fill">
      <mat-label>Poza lane</mat-label>
      <mat-select (selectionChange)="onPozaLaneChange($event)">
        <mat-option
          *ngFor="let currentPozaLaneSelected of ['', '1', '2', '3', '4']"
          [value]="currentPozaLaneSelected">
            {{ currentPozaLaneSelected }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-row" mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="saveChanges()">Save</button>
  </div>
</div>
