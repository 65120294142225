import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input('show-profile') showProfile = false;
  @Input() name = '';
  @Output() logout = new EventEmitter();

  constructor(private routerSrv: Router) { }

  ngOnInit() { }

  logoutUser() {
    this.logout.emit();
  }

  navigateToLogin() {
    this.routerSrv.navigateByUrl('/login');
  }

}
