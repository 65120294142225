<div class="flex-col">
  <div class="flex-row first-line">
    <div class="flex-row info">
      <div class="name">{{ vesselName || '-' }}</div>
      <span class="info-separator">|</span>
      <div class="code">Voyage {{ voyageId || '-' }}</div>
    </div>
    <div class="flex-row plane-type flex-grow">
      <div class="plane-type-toggle">
        <input id="status" type="checkbox" name="status">
        <label for="status">
          <div
            class="slot-status-switch"
            data-checked="LOADING"
            data-unchecked="DISCHARGE"
            (click)="onStatusSwitchChange()">
          </div>
        </label>
      </div>
    </div>
    <div class="flex-row cwp">CWP: {{ vesselName || '-' }}</div>
    <div class="flex-row more-options">
      <button
        color="primary"
        mat-icon-button
        [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [disabled]="statusSwitch === 'discharge'" (click)="toggleReeferMode()">
          {{ isDisconnectReeferModeSelected ? 'Cancel disconnect mode' : 'Disconnect reefers' }}
        </button>
        <button mat-menu-item *ngIf="statusSwitch === 'load'" (click)="dispatchPlugOffWave()">
          Dispatch Plug OFF
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="flex-row second-line">
    <div class="top-plane">
      <button mat-icon-button color="primary" (click)="onTopVisualizationMode()">
        <mat-icon>{{ isTopViewSelected ? 'fit_screen' : 'apps' }}</mat-icon>
      </button>
    </div>
    <div class="flex-row flex-grow bay-selector">
      <div class="flex-row prev-bay">
        <button
          color="primary"
          mat-icon-button
          [disabled]="selectedBayIndex === 0 || isTopViewSelected"
          (click)="goToPreviousBay()">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </div>
      <div class="flex-row curr-bay">
        <mat-form-field appearance="fill">
          <mat-label>Select the bay</mat-label>
          <mat-select [disabled]="isTopViewSelected" [(value)]="selectedBayIndex" (selectionChange)="onBaySelectionChange($event)">
            <mat-option *ngFor="let bay of iterableBays; let i = index" [value]="i">
              Bay {{ bay }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-row next-bay">
        <button
          color="primary"
          mat-icon-button
          [disabled]="selectedBayIndex === iterableBays.length - 1 || isTopViewSelected"
          (click)="goToNextBay()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </div>
    <div class="plane-switcher">
      <mat-radio-group [(ngModel)]="selectedPlane" (change)="onSwitchPlaneChange($event)">
        <mat-radio-button value="ALL" [disabled]="isTopViewSelected">ALL</mat-radio-button>
        <mat-radio-button value="DECK" [disabled]="isTopViewSelected">DECK</mat-radio-button>
        <mat-radio-button value="HATCH" [disabled]="isTopViewSelected">HATCH</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="flex-row third-line">
    <div class="flex-row load-sp-codes" *ngIf="statusSwitch === 'load'">
      <div class="sp-code" *ngFor="let loadSpCode of spCodesLegend?.load">
        {{ loadSpCode }}
      </div>
    </div>
    <div class="flex-row discharge-sp-codes" *ngIf="statusSwitch === 'discharge'">
      <div class="sp-code" *ngFor="let dischargeSpCode of spCodesLegend?.discharge">
        {{ dischargeSpCode }}
      </div>
    </div>
  </div>
</div>
