<!-- BayView -->
<div *ngIf="!isTopViewMode && cell.slot[isLoad ? 'load' : 'discharge']?.cellStatus !== 'LOCKED'"
  class="flex-col cell-wrapper cell-status-{{ cell.slot[isLoad ? 'load' : 'discharge'].cellStatus }}"
  [class.is-plugged-container]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isRequiredPlugOn"
  [class.is-remo]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isRemo"
  [class.is-loading]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isLoading"
  [class.is-pending-entry]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isPendingEntry"
  [class.is-in-transit]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isTransit"
  [class.is-marked-for-disconnecting]="isMarkedForDisconnecting"
  [ngStyle]="{'background-color': cell.slot[isLoad ? 'load' : 'discharge'].cellData?.colorRgb}" (click)="selectReeferToDisconnect(cell)">
  <div
    class="flex-row reefer-requester plug-off-req{{ cell.slot['load']?.cellData?.reqPlugOff }}"
    *ngIf="isLoad && cell.slot['load']?.cellData?.isReefer">
    <div
      class="flex-row reefer-icon"
      [class.plug-off-requested]="cell.slot['load']?.cellData?.reqPlugOff === 1"
      [class.plugged-off]="cell.slot['load']?.cellData?.reqPlugOff === 2">
      <mat-icon class="">power_off</mat-icon>
    </div>
  </div>
  <div *ngIf="cell.slot[isLoad ? 'load' : 'discharge']?.cellStatus !== 'NOT_AVAILABLE' && cell.slot[isLoad ? 'load' : 'discharge']?.cellStatus !== 'EMPTY'"
    class="flex-col header"
    [ngStyle]="{ 'background-color': cell.segment?.colorRgb }">
    <div class="flex-row main-row">
      {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrId }}
    </div>
    <div class="flex-row secondary-row spaced-between">
      <!-- POD -->
      <div class="flex-row">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.pod }}
      </div>
      <!-- Secuencia -->
      <div class="flex-row">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.sequence }}
      </div>
      <!-- Segmento -->
      <div class="flex-row">{{  cell.segment?.segmentId }}</div>
    </div>
  </div>
  <div *ngIf="cell.slot[isLoad ? 'load' : 'discharge']?.cellStatus !== 'NOT_AVAILABLE' && cell.slot[isLoad ? 'load' : 'discharge']?.cellStatus !== 'EMPTY'"
    class="flex-col content"
    [class.special-code]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.specialHdlCode">
    <div class="flex-row spaced-between">
      <!-- ISO -->
      <div class="flex-row">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrIso }}
      </div>
      <!-- Special Code -->
      <div class="flex-row special-code">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.specialHdlCode }}
      </div>
      <!-- Peso -->
      <div class="flex-row">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.grossWeight }}
      </div>
    </div>
    <div class="flex-row container-flags">
      <div class="flex-row imo-reefer">
        <mat-icon
          class="cold"
          fontIcon="ac_unit"
          *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isReefer">
        </mat-icon>
        <mat-icon
          class="heat"
          fontIcon="local_fire_department"
          *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.isImo">
        </mat-icon>
      </div>
      <!-- Tipo de contenedor -->
      <div class="flex-row container-type"
        [class.empty-20]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is20Empty"
        [class.full-20]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is20Full"
        [class.empty-40]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is40Empty"
        [class.full-40]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is40Full"
        [class.empty-45]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is45Empty"
        [class.full-45]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrSizeFlags?.is45Full">
      </div>
    </div>
    <div class="flex-row container-loc">
      <!-- Localización contenedor (nº bloque / stack / altura) -->
      <div class="flex-row">
        {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.ubication || '' }}
      </div>
    </div>
    <!-- Poza lane -->
    <div class="flex-row poza-lane">
      {{ cell.slot[isLoad ? 'load' : 'discharge'].cellData?.pozaLane }}
    </div>
  </div>
</div>

<!-- Locked cell -->
<div class="locked-cell" *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellStatus === 'LOCKED'">
  <div class="locked-line diagonal-1"></div>
  <div class="locked-line diagonal-2"></div>
</div>

<div class="oversized-cell" (click)="openModalToChangePozaLane(cell.slot[isLoad ? 'load' : 'discharge'].cellData?.pozaLane)">
  <svg class="oversized-cell-svg" width="100" height="100" viewBox="0 0 100 100">
    <!-- Oversizes (all) -->
    <path
      class="oversize"
      *ngFor="let p of paths"
      [attr.cntr-id]="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrId"
      [attr.oversize-flag]="p.oversizeFlag"
      [attr.d]="p.path" />
    <!-- isActive -->
    <path *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrMovStatusFlags?.isActive"
      class="container-mov-mask mov-active"
      d="M100,0L100,100L50,50Z" />
    <!-- isMoving -->
    <path *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrMovStatusFlags?.isMoving"
      class="container-mov-mask mov-moving"
      d="M100,0L0,100L100,100Z" />
    <!-- isEntering -->
    <path *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrMovStatusFlags?.isEntering"
      class="container-mov-mask mov-entering"
      d="M0,0L0,100L100,100L100,0L50,50Z" />
    <!-- isCompleted -->
    <g *ngIf="cell.slot[isLoad ? 'load' : 'discharge'].cellData?.cntrMovStatusFlags?.isCompleted">
      <path class="container-mov-mask mov-completed" d="M0,0L0,100L100,100L100,0L0,0Z" />
      <path class="container-mov-mask mov-completed" d="M0,0L100,100" />
      <path class="container-mov-mask mov-completed" d="M100,0L0,100" />
    </g>
  </svg>
</div>

<!-- TopView -->
<div class="flex-col" *ngIf="isTopViewMode">
  TOP!
</div>
