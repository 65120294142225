import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Voyage } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class VoyageService {

  constructor(private httpSrv: HttpClient) {}

  getVoyages() {
    return this.httpSrv.get<Array<Voyage>>(`${environment.apiUrl}/voyages/actives`);
  }

  setVoyageVisibility(voyageId: string) {
    return this.httpSrv.put(`${environment.apiUrl}/voyages/active/${voyageId}`, {});
  }

  setContainerPozaLane(voyageId: string, cntrId: string, pozaLane: string) {
    const epPath = `voyages/poza-lane/${voyageId}/${cntrId}/${pozaLane}`;
    return this.httpSrv.put(`${environment.apiUrl}/${epPath}`, {});
  }

}
