import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { PlannerViewAPIResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class PlaneDataService {

  constructor(private httpSrv: HttpClient) { }

  getInitialPlaneData(vesselName: string) {
    return this.httpSrv.get<PlannerViewAPIResponse>(environment.apiUrl + '/voyages/' + vesselName);
  }

  setReefersToDisconnect(voyageId: string, dispatchContainerList: Array<{ cntrId: string; ubication: string; }> = []) {
    return this.httpSrv.post<{ code: number; message: string; }>(environment.apiUrl + '/reefer/' + voyageId, dispatchContainerList);
  }

}
