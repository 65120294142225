import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PozaLaneDialogComponent } from 'src/app/components/poza-lane-dialog/poza-lane-dialog.component';
import { CellData, CellListElement, MOV_STATUS, PLANE_STATUS_SWITCH } from 'src/app/models';
import { VoyageService } from 'src/app/providers';
import { getOversizePath } from 'src/app/utils';

@Component({
  selector: 'app-cell-container',
  templateUrl: './cell-container.component.html',
  styleUrls: ['./cell-container.component.scss']
})
export class CellContainerComponent implements OnInit, OnChanges {

  @Input() x: number;
  @Input() y: number;
  @Input() cell!: CellListElement;
  @Input() isMarkedForDisconnecting: boolean;
  @Input() isTopViewMode: boolean;
  @Input() slotStatus: PLANE_STATUS_SWITCH | string;
  @Input() voyageId: string;
  @Output() addedContainerToReeferList: EventEmitter<string>;
  isLoad = true;
  paths: Array<any>;

  constructor(
    private dialogSrv: MatDialog,
    private snackBarSrv: MatSnackBar,
    private voyageSrv: VoyageService
  ) {
    this.x = 0;
    this.y = 0;
    this.addedContainerToReeferList = new EventEmitter<string>();
    this.isMarkedForDisconnecting = false;
    this.isTopViewMode = false;
    this.slotStatus = PLANE_STATUS_SWITCH.LOAD;
    this.paths = [];
    this.voyageId = '';
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['slotStatus']) {
      this.isLoad = changes['slotStatus'].currentValue === PLANE_STATUS_SWITCH.LOAD;
      const overSizes = this.cell.slot[this.isLoad ? 'load' : 'discharge']?.cellData?.overSize || [];
      this.paths = Object.entries(overSizes)
        .filter(oversizes => oversizes[1])
        .map(overSize => ({
          path: getOversizePath(overSize[0]),
          oversizeFlag: overSize[0]
        }));
    }
    // if(changes['reefersForThisBay']) {
    //   console.log(`Changes detected by "${this.cell.slot.load.cellData?.cntrId}"! `, changes.reefersForThisBay.currentValue);
    //   if(this.reefersForThisBay.has(this.cell.slot.load.cellData?.cntrId || '')) {
    //     console.log('Soy una celda a marcar! ', this.cell);
    //   }
    // }
  }

  selectReeferToDisconnect(cell: CellListElement) {
    this.addedContainerToReeferList.emit(cell.slot.load.cellData?.cntrId);
  }

  openModalToChangePozaLane(currentPozaLane: number | undefined) {
    const containerMov = this.cell.slot.load.cellData?.movement;
    if(this.slotStatus === PLANE_STATUS_SWITCH.LOAD
      && (containerMov?.movStatus === MOV_STATUS.O || containerMov?.movStatus === MOV_STATUS.L)
    ) {
      this.dialogSrv.open(PozaLaneDialogComponent, {
        width: '350px',
        data: { currentPozaLane: currentPozaLane || '' },
      }).afterClosed().subscribe(result => {
        if(result) {
          const opts = { duration: 5000 };
          this.voyageSrv.setContainerPozaLane(
            this.voyageId,
            this.cell.slot[this.slotStatus ? 'load' : 'discharge'].cellData?.cntrId || '',
            result?.newValue || ''
          ).subscribe({
            next: () => this.snackBarSrv.open('Poza lane successfully updated!', '', opts),
            error: () => this.snackBarSrv.open('Error updating poza lane!', '', opts),
          });
        }
      });
    }
  }

}
