import * as d3 from 'd3';

import { OverSize, OverSizeType } from 'src/app/models';

export const getOversizePath = (overSize: string | OverSize) => {
  const d3Path = d3.path();
  switch(overSize) {
    case OverSizeType.UP:
      d3Path.moveTo(0, 0);
      d3Path.lineTo(100, 0);
      d3Path.lineTo(50, -20);
      d3Path.closePath();
      return d3Path.toString();
    case OverSizeType.LEFT:
      d3Path.moveTo(0, 0);
      d3Path.lineTo(0, 100);
      d3Path.lineTo(-20, 50);
      d3Path.closePath();
      return d3Path.toString();
    case OverSizeType.RIGHT:
      d3Path.moveTo(100, 0);
      d3Path.lineTo(120, 50);
      d3Path.lineTo(100, 100);
      d3Path.closePath();
      return d3Path.toString();
    case OverSizeType.BOTTOM:
      d3Path.moveTo(0, 100);
      d3Path.quadraticCurveTo(50, 60, 100, 100);
      d3Path.closePath();
      return d3Path.toString();
    default: return '';
  }
}

