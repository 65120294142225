<mat-toolbar class="hutchison-toolbar">
  <mat-toolbar-row>
    <div class="title-content">
      <div class="title-logo" (click)="navigateToLogin()">
        <img src="assets/logos/Logo-Hutchison_white.svg" />
      </div>
      <div class="title-subname">BEST - PLANNER VIEW</div>
    </div>
    <div class="title-separator"></div>
    <div class="title-user-profile" *ngIf="showProfile">
      <div class="user-profile-img">
        <img src="assets/icons/generic-user-profile.svg" width="42px" />
      </div>
      <div class="user-data">
        <div class="name">{{ name }}</div>
        <!-- <div class="subname">SLISA</div> -->
      </div>
    </div>
    <div class="logout" *ngIf="showProfile">
      <button mat-icon-button aria-label="logout user" (click)="logoutUser()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
